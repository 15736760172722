import React, {useState} from "react";

function Checkbox() {
    const name = "Shirley";

    const activities = [
        {value: 1, label: "Picnicking"},
        {value: 2, label: "Cafe"},
        {value: 3, label: "Museum"},
        {value: 4, label: "Filing taxes together"},
        {value: 5, label: "Dining out"},
        {value: 6, label: "Arcade"},
        {value: 7, label: "Surprise me"},
    ];
    const [selectedActivities, setSelectedOptions] = useState(new Array(activities.length).fill(false));
    const [nextStep, setNextStep] = useState(false);

    const handleClickConfirm = () => {
        const filterActivities = activities.filter((activity, i) => selectedActivities[i]);
        setNextStep(true);
    };

    const handleCheckboxClick = (index) => {
        setSelectedOptions(selectedActivities.map((activity, i) => (i === index ? !activity : activity)));
    };

    const activitiesCheckbox = () => {
        return (
            <div className="text-center">
                Ok yay!!, what would you like to do as an activity (you can select multiple if you
                are unable
                to decide):
                <div className="w-full px-3 py-4">
                    <form className="rounded">
                        <div className="mb-4 flex items-center justify-center">
                            <ul className="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                {activities.map((activity, index) => (
                                    <li
                                        className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600"
                                        key={activity.value}
                                    >
                                        <div className="flex items-center ps-3">
                                            <input
                                                id={activity.value}
                                                type="checkbox"
                                                value={activity.value}
                                                checked={selectedActivities[index]}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                onChange={() => handleCheckboxClick(index)}
                                            />
                                            <label
                                                htmlFor={activity.value}
                                                className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                {activity.label}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-4 flex items-center justify-center">
                            <button
                                type="button"
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => handleClickConfirm()}
                            >
                                Confirm
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    const lastStep = () => {
        return (
            <div>
                <div className="text-center">
                    Thank you for taking the time to fill this in, you can let me know anytime if you would
                    like to
                    meet up (or not).
                    <img
                        src="https://media.tenor.com/Do2tfm6klgQAAAAM/cat-kitten.gif"
                        alt="Jumping cat meme"
                        width="220"
                        height="220"
                        className="m-auto"
                    />
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="text-sm text-center">
                    P.s. don't worry, I won't know what you filled in so there's no pressure
                </div>
            </div>
        );
    };
    return <>{!nextStep ? activitiesCheckbox() : lastStep()}</>;
}

export default Checkbox;
