import React, {createRef, useEffect, useState} from "react";
import "../App.css";
import Checkbox from "./checkbox";

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height,
    };
};

function Main() {
    const name = "Shirley";

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [noButtonStyle, setNoButtonStyle] = useState({top: 0, left: 0});
    const [titleStyle, setTitleStyle] = useState({top: 0, left: 0});
    const noButtonRef: React.RefObject<any> = createRef();
    const titleRef: React.RefObject<any> = createRef();

    const defaultNo = "No";
    const [defaultActive, setDefaultActive] = useState<boolean>(true);
    const noOptions = [
        "Are you sure?",
        "Really sure?",
        "Last chance!",
        "Think about it...",
        "Surely not?",
        "You might regret this!",
        "Give it another thought!",
        "Are you certainly sure?",
        "This could be a mistake!",
        "Have a heart!",
        "Don't be so cold!",
        "Change of heart?",
        "What if I say please?",
        "Wouldn't you reconsider?",
    ];
    const [noIndex, setNoIndex] = useState(0);
    const [yesClicked, setYesClicked] = useState(false);
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getNewNoButtonPosition = () => {
        return {
            top: windowDimensions.height / 2,
            left: (windowDimensions.width / 2) * 1.2,
        };
    };

    const getNewTitlePosition = () => {
        return {top: (windowDimensions.height / 2) * 0.85, left: (windowDimensions.width - getTitleWidth()) / 2};
    };
    useEffect(() => {
        setNoButtonStyle(getNewNoButtonPosition());
        setTitleStyle(getNewTitlePosition());
    }, [windowDimensions]);

    function randomIntFromInterval(min: number, max: number) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const yesButtonStyle = {
        top: windowDimensions.height / 2,
        left: (windowDimensions.width / 2) * 0.8,
    };

    const getButtonHeight = () => {
        return noButtonRef.current.clientHeight;
    };

    const getButtonWidth = () => {
        return noButtonRef.current.clientWidth;
    };

    const getTitleWidth = () => {
        return titleRef.current?.clientWidth;
    };

    const changeNoButtonPosition = () => {
        const getNewTop = randomIntFromInterval(0, windowDimensions.height - getButtonHeight());
        const getNewLeft = randomIntFromInterval(0, windowDimensions.width - getButtonWidth());
        setNoButtonStyle({top: getNewTop, left: getNewLeft});
    };

    const handleOnClickNo = () => {
        changeNoButtonPosition();

        let nextIndex = noIndex + 1;
        const limit = noOptions.length;

        if (nextIndex === limit) {
            nextIndex = 0;
        }
        setNoIndex(nextIndex);
        setDefaultActive(false);
    };

    // const handleClickBack = () => {
    //     setYesClicked(false);
    // };

    const handleOnClickYes = () => {
        console.log("CLick yes");
        setYesClicked(true);
    };

    const partOne = () => {
        return (
            <div>
                <div className="absolute" ref={titleRef} style={titleStyle}>
                    Hii {name}, Would you like to go on a date with me? :)
                </div>
                <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded absolute z-10"
                    style={yesButtonStyle}
                    onClick={() => handleOnClickYes()}
                >
                    Yes
                </button>
                <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded absolute z-0"
                    style={noButtonStyle}
                    onMouseEnter={() => changeNoButtonPosition()}
                    ref={noButtonRef}
                    onClick={() => handleOnClickNo()}
                >
                    {defaultActive ? defaultNo : noOptions[noIndex]}
                </button>
            </div>
        );
    };

    const partTwo = () => {
        return (
            <>
                <Checkbox/>
                {/*<button*/}
                {/*    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded absolute bottom-0"*/}
                {/*    onClick={() => handleClickBack()}*/}
                {/*>*/}
                {/*    Go back*/}
                {/*</button>*/}
            </>
        );
    };

    return <div className="Main">{!yesClicked ? partOne() : partTwo()}</div>;
}

export default Main;
